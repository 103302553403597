<template>
  <v-row>
    <v-col
      cols="12"
      class="mb-6"
      lg="4"
      md="4"
      xl="4"
    >
      <form-view @sendFormData="getFormData"></form-view>
    </v-col>
    <v-col
      cols="12"
      lg="8"
      md="8"
      xl="8"
    >
      <list-view :formData="formData"></list-view>
    </v-col>
  </v-row>
</template>

<script>
import { ref } from '@vue/composition-api'
import FormView from './form.vue'
import ListView from './list.vue'

export default {
  metaInfo: {
    title: 'Government Contributions',
  },

  setup() {
    const formData = ref('')

    const getFormData = val => {
      formData.value = val
    }

    return {
      getFormData,
      formData,
    }
  },

  components: {
    ListView,
    FormView,
  },
}
</script>
