<template>
  <v-card class="elevation-1">
    <v-card-title>
      Government Contribution
      <v-row>
        <v-col cols="12" md="4" lg="4" offset-md="8" offset-lg="8">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mb-2"
            @keypress.enter="searchTable=search"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="searchTable"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      dense
    >
    <template #[`item.gross_pay`]="{ item }">
      {{Intl.NumberFormat().format(item.gross_pay)}}
    </template>
    <template #[`item.basic_rate`]="{ item }">
      {{Intl.NumberFormat().format(item.basic_rate)}}
    </template>
    <template #[`item.employee_share`]="{ item }">
      {{Intl.NumberFormat().format(item.employee_share)}}
    </template>
    <template #[`item.employer_share`]="{ item }">
      {{Intl.NumberFormat().format(item.employer_share)}}
    </template>
    <template #[`item.premium`]="{ item }">
      {{Intl.NumberFormat().format(item.employee_share + item.employer_share)}}
    </template>
    <template #[`item.total`]="{ item }">
      <span v-if="formData.particular === 'pagibig' || formData.particular === 'philhealth'">
        {{Intl.NumberFormat().format(item.employee_share + item.employer_share)}}
      </span>
      <span v-else-if="formData.particular === 'sss'">
        {{Intl.NumberFormat().format(item.employee_share + item.employer_share + item.ec)}}
      </span>
    </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import getData from '@/composables/getData'
import fnx from '@/functions/fn'

export default {
  props: ['formData'],

  setup(props) {
    const search = ref('')
    const headers = ref([
      { text: 'First Name', align: 'start', value: 'firstname' },
      { text: 'Middle Name', value: 'middlename' },
      { text: 'Last Name', value: 'lastname' },
      { text: 'Birth Date', value: 'birthdate' },
      { text: 'Particular', value: 'type' },
      { text: 'ID Number', value: 'id_number' },
      { text: 'Employee Share', value: 'employee_share' },
      { text: 'Employer Share', value: 'employer_share' },
      { text: 'Total', value: 'total' },
    ])
    const items = ref([])
    const totalItems = ref(0)
    const searchTable = ref('')
    const options = ref({})
    const loading = ref(false)
    const groupBy = ref(null)
    const uri = ref(`${process.env.VUE_APP_URI}/api/payroll/reports/government-contributions`)

    watch(options, () => {
      getData(uri.value, items, totalItems, loading, options, search)
    })

    watch(searchTable, () => {
      getData(uri.value, items, totalItems, loading, options, search)
    })

    watch(() => props.formData, val => {
      items.value = []
      totalItems.value = 0
      if (val.groupBy === 'employee') {
        if (val.particular === 'pagibig') {
          headers.value = [
            { text: 'First Name', align: 'start', value: 'firstname' },
            { text: 'Middle Name', value: 'middlename' },
            { text: 'Last Name', value: 'lastname' },
            { text: 'Birth Date', value: 'birthdate' },
            { text: 'Pagibig Number', value: 'pagibig_no' },
            { text: 'Employee Share', value: 'employee_share' },
            { text: 'Employer Share', value: 'employer_share' },
            { text: 'Total', value: 'total' },
          ]
        } else if (val.particular === 'philhealth') {
          headers.value = [
            { text: 'First Name', align: 'start', value: 'firstname' },
            { text: 'Middle Name', value: 'middlename' },
            { text: 'Last Name', value: 'lastname' },
            { text: 'Birth Date', value: 'birthdate' },
            { text: 'SSS Number', value: 'sss_no' },
            { text: 'Date Hired', value: 'date_hired' },
            { text: 'Basic Rate', value: 'basic_rate' },
            { text: 'Gross Pay', value: 'gross_pay' },
            { text: 'Employee Share', value: 'employee_share' },
            { text: 'Employer Share', value: 'employer_share' },
            { text: 'Total', value: 'total' },
          ]
        } else if (val.particular === 'sss') {
          headers.value = [
            { text: 'First Name', align: 'start', value: 'firstname' },
            { text: 'Middle Name', value: 'middlename' },
            { text: 'Last Name', value: 'lastname' },
            { text: 'Birth Date', value: 'birthdate' },
            { text: 'SSS Number', value: 'sss_no' },
            { text: 'Date Hired', value: 'date_hired' },
            { text: 'Gross Pay', value: 'gross_pay' },
            { text: 'Employee Share', value: 'employee_share' },
            { text: 'Employer Share', value: 'employer_share' },
            { text: 'Premium', value: 'premium' },
            { text: 'EC Premium', value: 'ec' },
            { text: 'Total', value: 'total' },
          ]
        }
      } else if (val.groupBy !== 'employee') {
        if (val.particular === 'pagibig' || val.particular === 'philhealth') {
          headers.value = [
            { text: val.groupBy, align: 'start', value: 'location' },
            { text: 'Oracle Code', value: 'oracle_code' },
            { text: 'Employee Share', value: 'employee_share' },
            { text: 'Employer Share', value: 'employer_share' },
            { text: 'Total', value: 'total' },
          ]
        } else if (val.particular === 'sss') {
          headers.value = [
            { text: val.groupBy, align: 'start', value: 'location' },
            { text: 'Oracle Code', value: 'oracle_code' },
            { text: 'Employee Share', value: 'employee_share' },
            { text: 'Employer Share', value: 'employer_share' },
            { text: 'EC', value: 'ec' },
            { text: 'Total', value: 'total' },
          ]
        }
      }
      uri.value = `${process.env.VUE_APP_URI}/api/payroll/reports/government-contributions/${fnx.base64_encode(val)}`
      getData(uri.value, items, totalItems, loading, options, search)
      groupBy.value = val.groupBy
    })

    return {
      search,
      headers,
      items,
      searchTable,
      options,
      totalItems,
      loading,
      groupBy,
    }
  },
}
</script>
